<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path:'/illnessManage' }">疾病管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
            <el-breadcrumb-item>添加医生</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <!-- 搜索医生： -->
            <el-input placeholder="请输入医生姓名或电话号" clearable prefix-icon="el-icon-search" v-model="search"
                style="width: 280px;" size="small">
            </el-input>
            <el-button type="primary" style="margin-left:20px" @click="query" size="small">查询</el-button>
            <!-- <el-select v-model="select" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-button type="primary" style="margin-left:20px" @click="query">添加</el-button> -->
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="id" v-if="false"></el-table-column>
            <el-table-column prop="name" label="医生姓名"></el-table-column>
            <el-table-column label="年龄" prop="age"></el-table-column>
            <el-table-column label="性别" prop="sex"></el-table-column>
            <el-table-column label="所在医疗机构" prop="hospital"></el-table-column>
            <el-table-column label="职称" prop="rank"></el-table-column>
            <el-table-column label="擅长治疗" prop="goodat"></el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <div class="opt">
                        <el-button type="warning" size="mini" @click="del(scope.row,scope.$index)">解除绑定</el-button>
                        <!-- <a @click="del(scope.row,scope.$index)">解除绑定</a> -->
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
        <el-dialog title="医生详情" :visible.sync="dialogFormVisible">
            <el-form v-model="form" class="tcform" label-width="170px" style="text-align: left;">
                <!-- <el-form-item label="医生id：">
                    <span>{{form.dp_id}}</span>
                </el-form-item> -->
                <el-form-item label="姓名：">
                    <span>{{form.name}}</span>
                </el-form-item>
                <el-form-item label="电话：">
                    <span>{{form.tel}}</span>
                </el-form-item>
                <el-form-item label="年龄：">
                    <span>{{form.age}}</span>
                </el-form-item>
                <el-form-item label="性别：">
                    <span>{{form.sex}}</span>
                </el-form-item>
                <el-form-item label="所在医疗机构：">
                    <span>{{form.hospital}}</span>
                </el-form-item>
                <el-form-item label="医疗机构级别：">
                    <span>{{form.hospital_rank}}</span>
                </el-form-item>
                <el-form-item label="职称：">
                    <span>{{form.rank}}</span>
                </el-form-item>
                <el-form-item label="擅长治疗：">
                    <span>{{form.goodat}}</span>
                </el-form-item>
                <el-form-item label="已绑定疾病：">
                    <span>{{bindillname}}</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false;form = {}">取 消</el-button>
                <el-button type="primary" @click="sub(form)">绑 定</el-button>
                <el-button type="warning" @click="del(form)">解 绑</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        inject: ['reload'],
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                name: '',
                id: '',
                options: [],
                select: '',
                search: '',
                dialogFormVisible: false,
                form: {},
                bindillname:''
            }
        },
        mounted() {
            this.name = this.$route.query.name;
            this.id = this.$route.query.id;
            // this.getdoc();
            this.getdoclist(1);
        },
        methods: {
            getdoc() {
                this.axios.get("/gu/get_gu_docs?page=1&size=100000&flag=-1")
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.options = res.data.result;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            getdoclist(page) {
                this.axios.get("/gu/get_gu_ill_lib_doc_by_ill?ill_lib_id=" + this.id + "&page=" + page + "&size=" + this.PageSize + "&flag=-1")
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.totalCount = res.data.count;
                            this.list = res.data.result;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            async query() {
                const projectObj = await this.cha();
                const projectObj1 = await this.docdetail(projectObj.id)
                this.bindillname = projectObj1;
                console.log(projectObj)
                console.log(projectObj1)
            },
            cha(){
                const projectObj = this.axios.post("/gu/search_gu_docs", this.qs.stringify({ keyword: this.search ,is_flag:'all'}))
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.form = res.data.result[0];
                            this.dialogFormVisible = true;
                            return res.data.result[0];
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    return projectObj;
            },
            docdetail(doc_id) {
                let str = '';
                const projectObj = this.axios.get("/gu/get_gu_ill_lib_doc_by_doc?doc_id=" + doc_id)
                    .then((res) => {
                        if (res.data.code == 0) {
                            let array = res.data.result;
                            if (res.data.result.length > 1) {
                                for (let i = 0; i < array.length; i++) {
                                    const element = array[i];
                                    str += element.name + '，'
                                }
                                str = str.substring(0, str.length - 1);
                            } else {
                                str = res.data.result[0].name;
                            }
                            return str;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    return projectObj;
            },
            sub(form) {
                this.axios.post("/gu/insert_gu_ill_lib_doc", this.qs.stringify({ doc_id: form.id, ill_lib_id: this.id }))
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$message({
                                message: '绑定成功',
                                type: 'success'
                            });
                            this.reload();
                        } else {
                            this.$message(rew.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            del(row) {
                this.$confirm('此操作将解除绑定关系, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/gu/delete_gu_ill_lib_doc", this.qs.stringify({ doc_id: row.id, ill_lib_id: this.id }))
                        .then((res) => {
                            console.log(res);
                            if (res.data.code == 0) {
                                this.$message({
                                    message: '解除绑定成功',
                                    type: 'success'
                                });
                                this.reload();
                            } else {
                                this.$message(rew.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消解除绑定'
                    });
                });
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getdoclist(currentPage, this.PageSize);
            },
        },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }

    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .el-select {
        float: left;
    }

    .add .el-button {
        margin-right:20px;
    }
</style>